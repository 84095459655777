import React from 'react';
import './contactstyle.css';
import ReCAPTCHA from "react-google-recaptcha";
import call from '../../Image/Call_Deploy intelligent automation copy 3.png';
import gmail from '../../Image/Gmail_Deploy intelligent automation copy 2.png';
import locatiomn from '../../Image/Location_Deploy intelligent automation copy 4.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactPage = () => {
    const baseUrl = "http://localhost:8000";

    const initialValues = {
        name: '',
        email: '',
        number: '',
        message: '',
        captchaValue: null,
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        number: Yup.string()
            .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
            .required('Mobile number is required'),
        message: Yup.string().required('Message is required'),
        captchaValue: Yup.string().required('Please complete the captcha'),
    });

    const sendEmail = async (values, { resetForm }) => {
        const { name, email, number, message } = values;

        const dataSend = { name, email, number, message };
        toast.success("Email sent successfully!");
        try {
            const res = await fetch(`${baseUrl}/email/sendEmail`, {
                method: "POST",
                body: JSON.stringify(dataSend),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            if (res.status === 200) {
                resetForm(); 
            } else {
                toast.error("Failed to send email.");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    const onCaptchaChange = (value, setFieldValue) => {
        setFieldValue('captchaValue', value);
    };

    return (
        <>
            <ToastContainer position='top-center' reverseOrder={false} />
            <section>
                <div className="row text-center">
                    <div className="col-sm-12">
                        <div className="contact-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0707803109544!2d75.8886028108396!3d22.725610427258992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd69fc801f0b%3A0x7f5839fb31bd5186!2sKSVSoftTech!5e0!3m2!1sen!2sin!4v1709118155712!5m2!1sen!2sin"
                                style={{ width: '100%', height: 460, border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className='contact-sec2'>
                    <div className="container erp-pd">
                        <div className="row">
                            <h1 className='text-center text-white'>Get In Touch</h1>
                            <p className='text-center text-white'>Let us help your business to move forward.</p>
                        </div>
                        <div className='row pd-60'>
                            <div className="col-md-8 col-sm-12">
                                <div className="card rounded border-0 shadow p-4">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={sendEmail}
                                    >
                                        {({ setFieldValue }) => (
                                            <Form className='p-md-5 p-2' autocomplete="off">
                                                <div className="row">
                                                    <h3 className='text-center p-4'>Contact Us</h3>
                                                    <div className="col-12 mb-3">
                                                        <label htmlFor="name">Name</label>
                                                        <Field
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder="Enter your name"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="email">Email</label>
                                                        <Field
                                                            type="email"
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                        />
                                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="number">Number</label>
                                                        <Field
                                                            type="text"
                                                            id="number"
                                                            name="number"
                                                            className="form-control"
                                                            placeholder="Mobile Number"
                                                        />
                                                        <ErrorMessage name="number" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="md-form mb-3">
                                                        <label htmlFor="message">Message</label>
                                                        <Field
                                                            as="textarea"
                                                            id="message"
                                                            name="message"
                                                            className="md-textarea form-control"
                                                            rows="3"
                                                            placeholder="Type your message here.."
                                                        />
                                                        <ErrorMessage name="message" component="div" className="text-danger" />
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 mt-1'>
                                                        <ReCAPTCHA
                                                            sitekey="6LcpQxknAAAAAK3ghNJxnfjJNH5n7kMUDIB9hVjO"
                                                            onChange={(value) => onCaptchaChange(value, setFieldValue)}
                                                        />
                                                        <ErrorMessage name="captchaValue" component="div" className="text-danger" />
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 text-end'>
                                                        <button type="submit" className="btn btn-primary btn-shadow btn-rounded">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 gap-md-2">
                            <div className='row text-center justify-content-center'>
                                    <div className="col-md-12 mb-md-1">
                                        <div className="card service-wrapper rounded border-0 shadow p-4">
                                            <div className="contact-detail-box">
                                                <img src={gmail} style={{ width: 30 }} alt="Email" />
                                                <h4>Email</h4>
                                                <a href="mailto:info@ksvsofttech.com" className="text-muted">info@ksvsofttech.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-md-1">
                                        <div className="card service-wrapper rounded border-0 shadow p-4">
                                            <div className="contact-detail-box">
                                                <img src={call} style={{ width: 30 }} alt="Call" />
                                                <h4>Give us a call</h4>
                                                <p className="text-center text-muted">093014 25492</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-md-1">
                                        <div className="card service-wrapper rounded border-0 shadow p-4">
                                            <div className="contact-detail-box">
                                                <img src={locatiomn} style={{ width: 30 }} alt="Location" />
                                                <h4>Our Location</h4>
                                                <address>
                                                    201, Labbik Regency, Old Palasia, Indore, Madhya Pradesh 452001
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactPage;
