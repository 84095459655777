import React from 'react'
import instDEshboard from './solutionImg/instDesh.png'
import crmCircle from './solutionImg/CRM & Marketing Automation-03.png'
import feature from './solutionImg/Features to look_Artboard 1.png'
import checked from './solutionImg/checked.png'
import intregation from './solutionImg/Integrate.png'
import migrate from "./solutionImg/Effortlessly migrate.png"
import selfS from './solutionImg/Provide self-service portals.png'
import deploy from './solutionImg/Deploy intelligent automation.png'
import addCycle from './solutionImg/Manage your admission lifecycle.png'
import collabrate from './solutionImg/menuicons-24.png'
import ContactUs from '../contactUs/ContactUs'
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'
import whatIsCrm from './solutionImg/What is Institutional CRM.png'

const featureArr = [
    "Effortlessly store and manage student information.",
    "Streamline critical academic processes.",
    "Enhance communication channels with students, parents, and faculty.",
    "Analytics and Reporting.",
    "Marketing Automation.",
    " Prioritize the security and privacy of sensitive student information with robust data encryption.",
]

const ksvCrm = [
    { img: intregation, tag: "Integrate", tagLine: "Seamlessly connect with other institutional systems." },
    { img: migrate, tag: "Effortlessly migrate", tagLine: "Smooth data transition from legacy systems." },
    { img: selfS, tag: "Provide self-service portals", tagLine: "Empower users with easy information access." },
    { img: deploy, tag: "Deploy intelligent automation", tagLine: "Streamline processes with AI-driven solutions." },
    { img: addCycle, tag: "Manage your admission lifecycle", tagLine: "Comprehensive tools for the entire enrollment process." },
    { img: collabrate, tag: "Contextually collaborate", tagLine: "Enable team cooperation with relevant data sharing." },
]

const IntitutionalCRM = () => {
    return (
        <>
            <section className='crm-first section'>
                <div className='crm-overlay' />
                <div className='container'>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className='home-5-content row d-flex align-items-center' >
                                <div className='col-md-8'>
                                    <h1 className='home-5-title font-52 mt-md-0'>
                                        SIS & marketing automation
                                    </h1>
                                    <p className='fs-14 mt-md-3 mt-2 txt-justify'>
                                        Unlock the Power of Seamless Management and Enhanced Communication with KSVSoftTech Student Information System & Marketing Automation Solutions for Educational Institutions.
                                    </p>
                                    {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader">
                                        <span className="px-1 d-inline-block fontFamily fw-500">Videos </span>
                                    </p> */}
                                </div>
                                <div className='col-md-4 '>
                                    <img src={crmCircle} className='crmH-img mt-md-0 mt-4 img-fluid' alt="" srcSet="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='crm-second'>
                <div className='container-lg  erp-pd'>

                    <div className='home-5-content row d-flex ' >
                        <div className='col-md-6 text-center  crm-card'>
                            <h3 className='fw-600'>
                                What is Student Information System ?
                            </h3>
                            <img src={whatIsCrm} className='que-img img-fluid' alt="" srcSet="" />
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div className=' crm-content left '>
                                Our student information system software is meticulously designed to cater to the unique needs of educational institutions, providing a comprehensive solution for managing student data, academic processes, administrative tasks, and marketing automation.
                            </div>
                        </div>
                    </div>
                    <div className='home-5-content mt-md-2 mt-3 row d-flex' >
                        <div className='col-md-6  order-md-1 order-2 d-flex align-items-center'>
                            <div className=' crm-content right'>
                                <ul>
                                    {
                                        featureArr?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16' > {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 text-center order-md-2 order-1 crm-card'>
                            <h3 className='fw-600'>
                                Features to look for in an SIS software
                            </h3>
                            <img src={feature} className='que-img img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <h1 className='text-md-center font-48 fw-600 ' >Increase enrollments, and streamline operations with our SIS  
                    {/* <span className='higlight'> enrollments, and streamline </span>  */}
                     </h1>
                    <p className='text-md-center fs-18 mt-md-2 mb-md-5 mb-4 px-md-5'>
                        Store and manage student information, including enrollment details, academic records, and contact information</p>
                    <div className='text-center'>
                        <img src={instDEshboard} className='crmDeshboard img-fluid mt-md-5' alt="" srcSet="" />
                    </div>
                    <div className='row d-flex justify-content-center mt-md-5 mt-3'>
                        <div className="col-lg-4 col-md-6 ">

                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-third erp-pd'>
                <div className='container'>
                    <div className='row d-flex justify-content-center '>
                        <h1 className='text-md-center font-48 fw-600' >Why choose KSVSoftTech SIS for your institude?
                         {/* <span className='higlight' > KSVSoftTech CRM </span>  */}
                           </h1>
                        <p className='text-md-center fs-18 mb-md-5 pb-md-5 mb-4 mt-md-2'>An SIS software is built with a dynamic set of features for all educational institutes.</p>
                        {
                            ksvCrm?.map((item, index) => (
                                <div key={`${item?.tag + "-" + index}`} className="col-lg-4 col-md-6 ">
                                    <div className="card-feature " >
                                        <div className="card-body text-center">
                                            <img src={item?.img} className='hw-logo' alt="" />
                                            <h6 className="card-subtitle mt-md-3 mb-md-1 ">{item?.tag}</h6>
                                            <p className="card-text fs-12 text-muted">{item?.tagLine}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            {/* <ContactUs /> */}
            <FAQ />
            <BottomFooterLine />
        </>
    )
}

export default IntitutionalCRM