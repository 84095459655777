import React, { useEffect } from "react";
import "./particles.js";
import { particlesConfig } from "./particles.js";
import "./solutionStyle.css";
import checked from "./solutionImg/Group 405.png";
import feature1 from "./solutionImg/feature1.png";
import feature2 from "./solutionImg/feature2.png";
import feature3 from "./solutionImg/feature3.png";
import feature4 from "./solutionImg/feature4.png";
import feature5 from "./solutionImg/feature5.png";
import feature6 from "./solutionImg/feature6.png";
import initialIMg1 from "./solutionImg/Group 398.png";
import initialIMg2 from "./solutionImg/Group 397.png";
import initialIMg3 from "./solutionImg/Group 396.png";
import initialIMg4 from "./solutionImg/Group 395.png";
import initialIMg5 from "./solutionImg/Group 399.png";
import initialIMg6 from "./solutionImg/Group 400.png";
import initialIMg7 from "./solutionImg/Group 401.png";
import initialIMg8 from "./solutionImg/Group 402.png";
import initialIMg9 from "./solutionImg/Group 403.png";
import BottomFooterLine from "../BottomFooterLine.jsx";
import ewallet from "./solutionImg/Doodal.png";
import productDevelopment from "./solutionImg/Group 175.png";
import plannning from "./solutionImg/Group 406.png";
import accounting from "./solutionImg/Group 408.png";
import chain from "./solutionImg/Group 410.png";
import cusM from "./solutionImg/Group 412.png";
import prjM from "./solutionImg/Group 413.png";
import prjM2 from "./solutionImg/Group 414.png";
import FAQ from "../FAQ.jsx";

const erpFeature = [
  {
    features: [
      "Performance and productivity metrics.",
      "Attendance and leave reports",
      "Recruitment and attrition analysis",
      "Compliance and audit reports",
    ],
    img: accounting,
  },
  {
    features: [
      "Personal details, job and information",
      "Employment history and performance",
      "Document management",
    ],
    img: chain,
  },
  {
    features: [
      "Leave and attendance tracking",
      "Performance appraisals",
      "Employee benefits management",
      "Disciplinary actions and grievances..",
    ],
    img: plannning,
  },
  {
    features: ["Expense reimbursement", "Payslip generation and distribution"," Automation process" ,"Custom allowance"],
    img: prjM,
  },
  {
    features: [
      "Resume parsing and storage",
      "Interview scheduling and feedback",
      "Candidate evaluation and selection",
    ],
    img: cusM,
  },
  {
    features: ["Group chats", "File sharing and collaboration" ,"One-on-One Chats" ,"Media Sharing"],
    img: prjM2,
  },
];

const keyFeatures1 = [
  {
    feature: "Streamlined HR Processes",
    img: feature1,
    points:
      "Automates tasks like payroll, attendance, and leave management, reducing manual work and errors.",
  },
  {
    feature: "Improved Data Management",
    img: feature2,
    points:
      "Centralizes employee information, making it easy to access, update, and maintain accurate records.",
  },
  {
    feature: "Enhanced Employee Experience",
    img: feature3,
    points:
      "Simplifies processes like onboarding, training, and performance reviews, boosting satisfaction and engagement.",
  },
];
const keyFeatures2 = [
  {
    feature: "Compliance and Security",
    img: feature4,
    points:
      "Ensures adherence to labor laws and data protection standards, minimizing legal risks.",
  },
  {
    feature: "Data-Driven Decisions",
    img: feature5,
    points:
      "Provides analytics and reports that help HR teams make informed decisions based on real-time data.",
  },
  {
    feature: "Customizable Solutions",
    img: feature6,
    points:
      "We customize the software to meet your organization unique needs and workflows.",
  },
];

const Hwforces = () => {
  useEffect(() => {
    window.particlesJS("particles-js", particlesConfig);
    return () => {
      window.cancelAnimationFrame(window.requestAnimFrame);
    };
  }, []);

  return (
    <>
      <section className="section home-5-bg" id="home">
        <div id="particles-js" />
        <div className="bg-overlay" />
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-md-center mpbile-pd home-5-content">
                    <h1 className="home-5-title text-white font-52 mt-md-0 mt-4">
                      HWForces - your ultimate HR management solution!
                    </h1>

                    <a
                      href="https://hwforces.com/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn  col-lg-2 col-md-3 col-5 rounded  text-white hw-btn  py-md-3 px-md-3  theme-color "
                    >
                      <span className="px-1 d-inline-block fontFamily fw-500">
                        Get a Demo
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="erp-second">
        <div className="container-lg">
          {/* <h1 className='home-5-title '>What is Accounting & Finance with ERP and what does it do for business?</h1> */}
          <div className="row margin-top d-flex align-items-center ">
            <div className="col-md-6 order-md-2 order-2 d-flex justify-content-center align-items-center">
              <img
                src={productDevelopment}
                className="desktop-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-6 ps-md-5 ps-0 order-md-1 order-1">
              <h1 className="home-5-title font-48 fw-600 mt-md-0 mt-4">
                Introduction to HWForces
                {/* <span className="higlight">  Development </span> */}
              </h1>
              <p className="fs-16 mt-md-3 mt-1 txt-justify">
                The Human Resource Management System (HRMS) is a comprehensive
                software solution designed to streamline and automate various HR
                processes within an organization. It serves as a centralized
                database that stores and manages employee information,
                facilitates recruitment and onboarding, tracks employee
                performance, and handles payroll and benefits administration.
                The purpose of the HRMS is to enhance HR efficiency, improve
                data accuracy, and provide a seamless employee experience. By
                digitizing and automating HR tasks, the HRMS simplifies
                administrative processes, reduces manual errors, and frees up HR
                professionals to focus on strategic initiatives and employee
                development .
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container ew-second">
          <div className="row container d-flex mx-auto align-items-center justify-content-lg-center">
            <div className="col-12 text-center">
              <h1 className="font-weight-normal home-5-title font-48">
                Key Feature
                {/* <span className="higlight fw-700 "> Feature</span>  */}
              </h1>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
              <div className="col-12 col-md-4 mt-3 mt-md-3">
                {keyFeatures1?.map((item, index) => (
                  <div key={`${item?.feature}-${index}`} className="mb-4">
                    <div className="card-feature-wallet">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <img src={item?.img} className="wallet-logo" alt="" />
                          <h6 className="card-subtitle mb-md-1 ml-2">
                            {item?.feature}
                          </h6>
                        </div>
                        <p className="card-text fs-12 text-muted">
                          {item?.points}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <img
                src={ewallet}
                className="ewallet-img col-12 col-md-4 mt-4 mt-md-4 "
                alt=""
              />
              <div className="col-12 col-md-4 mt-3 mt-md-3">
                {keyFeatures2?.map((item, index) => (
                  <div key={`${item?.feature}-${index}`} className="mb-4">
                    <div className="card-feature-wallet">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-end ">
                          <h6 className="card-subtitle mb-md-1 mr-2">
                            {item?.feature}
                          </h6>
                          <img
                            src={item?.img}
                            className="wallet-logo1"
                            alt=""
                          />
                        </div>
                        <p className="d-flex card-text fs-12 text-muted justify-content-end">
                          {item?.points}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container-lg ew-second">
          <div className="text-center">
            <h1 className="home-5-title ">How to access the HRMS demo</h1>
          </div>
          <div className="row mt-md-5 mt-3 d-flex ">
            <div className="col-md-6 text-center hw-padding">
              <img
                src={initialIMg1}
                style={{ width: "22rem" }}
                className=" mt-md-0 mt-3 img-fluid"
                alt=""
                srcSet=""
              />
              <p class="mt-2 fw-600  align-items-center">
                <li>Use the provided demo credentials to log in.</li>
              </p>
            </div>
            <div className="col-md-6 ps-0  d-flex align-items-center hw-padding">
              <div className="mt-50">
                <div className="d-flex align-items-center">
                  <i class="fa-solid fa-circle-arrow-left fs-3"></i>
                  <button
                    type="button"
                    style={{ backgroundColor: "#445474" }}
                    class="btn fw-600 font-12 px-1 py-2 text-light ms-1"
                  >
                    Demo Credentials
                  </button>
                </div>
              </div>
              <img
                src={initialIMg2}
                style={{ width: "16rem" }}
                className="mt-md-0 mt-3 img-fluid"
                alt=""
                srcSet=""
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container-lg hw-second">
          <div className="text-end">
            <img
              src={initialIMg3}
              style={{ width: "22rem" }}
              className="me-md-5 img-fluid me-5"
              alt=""
              srcSet=""
            />
          </div>
          <div className="row mt-md-5 mt-3 d-flex ">
            <div className="col-md-8 text-end">
              <img
                src={initialIMg4}
                style={{ width: "42rem" }}
                className=" mt-md-0 mt-3 img-fluid"
                alt=""
                srcSet=""
              />
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="col-md-12 d-flex flex-column align-items-justify mb-3 hw-res-2">
                <img
                  src={initialIMg5}
                  style={{ width: "18rem" }}
                  className="mt-md-0 mt-3 mb-3 img-fluid"
                  alt=""
                  srcSet=""
                />
                <ul>
                  <li>Register with your email address</li>
                  <li>Verify your email using the OTP sent to you</li>
                </ul>
              </div>
              <div className="col-md-12 d-flex flex-column align-items-justify mb-3 hw-res-1">
                <img
                  src={initialIMg6}
                  style={{ width: "18rem" }}
                  className="mt-md-0 mt-3 mb-3 img-fluid"
                  alt=""
                  srcSet=""
                />
                <ul>
                  <li>Log in with your registered email address</li>
                  <li>Verify your identity using the OTP sent to your email</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex justify-content-center">
            <div className="col-md-6 text-center hw-res">
              <img
                src={initialIMg7}
                style={{ width: "42rem" }}
                className=" mt-md-0 mt-3 img-fluid"
                alt=""
                srcSet=""
              />
              <p class="mt-2 fw-600 text-start">
                <li>
                  <span> Once your OTP is successfully verified,</span>
                  <br />
                  <span>you'll be directed to the HRMS dashboard.</span>
                </li>
              </p>
            </div>
            <div className="col-md-4 ps-0 d-flex align-items-center justify-content-center ">
              <img
                src={initialIMg8}
                style={{ width: "24rem" }}
                className="mt-md-0 mt-3 img-fluid"
                alt=""
                srcSet=""
              />
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex demo-link align-items-center">
            <div className="note-container d-flex justify-content-between align-items-center">
              <span className="note-text">
                Note: To access the demo, please click on
                <strong> Get a Demo</strong> button
              </span>
              <div className="arrow"></div>
              <a
                href="https://hwforces.com/login"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-1 fw-600 font-12 px-4 py-2 ms-3"
              >
                Get a Demo
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <section className="erp-third">
        <div className="container">
          <h1 className="text-center ">HRMS Functions</h1>
          <div className="row d-flex justify-content-center mt-md-5 mt-3 mb-6">
            {erpFeature?.map((item, index) => (
              <div
                key={`${item?.feature + "-" + index}`}
                className="col-lg-4 col-md-6 "
              >
                <div className="featureCard">
                  <div className="card-body text-center">
                    <img src={item?.img} className="img-fluid" alt="" />
                    <div className="d-flex align-items-center mt-1 mx-2">
                      <p className=" mt-md-1 mb-md-1 title-text-1">
                        <ul className="list-unstyled">
                          {item?.features?.map((feature, idx) => (
                            <li
                              key={idx}
                              className="d-flex align-items-center mt-1 mx-2"
                            >
                              <img
                                src={checked}
                                className="checked-img1 me-1"
                                alt="img"
                              />
                              <p className="mt-md-1 mb-md-1 title-text-1">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <ContactUs /> */}
      <FAQ />
      <BottomFooterLine />
    </>
  );
};

export default Hwforces;
